import { Button, Heading, Link, Section } from '@troon/ui';
import { Suspense, For, createSignal, Show } from 'solid-js';
import { IconSearchMagnifyingGlass } from '@troon/icons/search-magnifying-glass';
import { Dynamic } from 'solid-js/web';
import { IconUserAdd } from '@troon/icons/user-add';
import { useUser } from '../../../../providers/root';
import { Content } from '../../../../components/main-content';
import { changeLog } from './changelog';
import type { ParentProps } from 'solid-js';
import type { RouteSectionProps } from '@solidjs/router';
import type { IconComponent } from '@troon/icons';

export default function Facility(props: RouteSectionProps) {
	const userData = useUser();
	const [changeLogCount, setChangeLogCount] = createSignal(5);
	return (
		<Content>
			<Suspense>
				<Heading as="h1">Welcome, {userData()?.firstName}</Heading>

				<Heading as="h2" size="h3">
					Quick Links
				</Heading>

				<div class="grid grid-cols-1 gap-4 xl:grid-cols-3 xl:gap-8">
					<LinkTile
						title="Look up a Customer"
						icon={IconSearchMagnifyingGlass}
						href={`/facility/${props.params.facility}/customer`}
					/>

					<LinkTile
						title="Sign up new user"
						icon={IconUserAdd}
						href={`/facility/${props.params.facility}/customer/new`}
					/>
				</div>

				<Heading as="h2" size="h3">
					Latest Changes
				</Heading>

				<div class="flex flex-col gap-4">
					<For each={changeLog.slice(0, changeLogCount())}>
						{(log) => (
							<Section appearance="contained">
								<Heading as="h4" size="h5">
									{log.date.toLocaleDateString()}
								</Heading>

								<ul class="flex flex-col gap-4">
									<For each={log.entries}>
										{(entry) => (
											<li>
												<div class="font-semibold">{entry.title}</div>
												<div>{entry.description}</div>
											</li>
										)}
									</For>
								</ul>
							</Section>
						)}
					</For>
				</div>

				<Show when={changeLogCount() < changeLog.length}>
					<Button onClick={() => setChangeLogCount(changeLog.length + 10)}>See More</Button>
				</Show>
			</Suspense>
		</Content>
	);
}

function LinkTile(props: ParentProps<{ href: string; title: string; icon: IconComponent }>) {
	return (
		<div class="relative flex flex-col gap-2 rounded border border-neutral bg-white p-4 hover:border-brand hover:bg-brand-100 xl:p-8">
			<Dynamic component={props.icon} class="size-12 text-brand" />
			<Link href={props.href} class="text-lg font-semibold after:absolute after:inset-0">
				{props.title}
			</Link>
			{props.children}
		</div>
	);
}
